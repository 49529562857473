import React, { useState ,useEffect } from 'react'
import { BrowserRouter as Router, Route, Link, Redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import { setCookie } from '../services/auth.js';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import OtpInput from 'react-otp-input';
import { toast, ToastContainer } from 'react-toastify';
import { useAppContext } from '../context/AppContext';
import ReCAPTCHAComponent from '../components/common/ReCaptcha';
import { validateEmail } from '../services/validators'
import '../assets/styles/base.css';
import '../assets/styles/login.css';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/img/sciative_logo.png';
import padlock from '../assets/img/padlock.jpg';
import loginBg from '../assets/img/login-bg.png';
import DOMPurify from 'dompurify';
import useUserStore from "../context/UserContext";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
	const {
		name,
		email,
		role,
		setName,
		setEmail,
		setRole
	} = useUserStore();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [captcha, setCaptcha] = useState('');

	const [shouldShowCaptcha, setShouldShowCaptcha] = useState(true);

	const [open, setOpen] = React.useState(false);
	const [msg, setMsg] = React.useState('');
	const [modalOpen, setModalOpen] = React.useState(false);
	const [code, setCode] = useState('');

	//Forgot password variables
	const [step, setStep] = useState(1);
	const [email2, setEmail2] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [helperText, setHelperText] = useState('');
	const [otp, setOtp] = useState('');
	const [password1, setPassword1] = useState("");
	const [showPassword1, setShowPassword1] = React.useState(false);
	const [password2, setPassword2] = useState("");
	const [showPassword2, setShowPassword2] = React.useState(false);
	const [invalidOTP, setInvalidOTP] = useState(false);
	const [OTPErrorText, setOTPErrorText] = useState(false);
	const [pswdError, setPswdError] = useState(false);
	const [pswdValidations, setPswdValidations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOTPSent, setIsOTPSent] = useState(false);
	const [sendOTPLoading, setSendOTPLoading] = useState(false);
	const [reSendOTPLoading, setReSendOTPLoading] = useState(false);
	const [confirmOTPLoading, setConfirmOTPLoading] = useState(false);
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

	const { setCurrUser } = useAppContext();

	const handleClick = () => {
		setOpen(true);
	};
	useEffect(() => {
        fetch("/api/show_captcha")
            .then((response) => response.json())
            .then((data) => {
                setShouldShowCaptcha(data.showCaptcha);
            })
            .catch((error) => {
                console.error("Error fetching showCaptcha:", error);
            });
    }, []);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const closeModal = (event, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setModalOpen(false);
		setStep(1);
		setEmail2('');
		setEmailError(false);
		setHelperText('');
		setOtp('');
		setPassword1('')
		setPassword2('')
		setShowPassword1(false)
		setShowPassword2(false);
		setInvalidOTP(false);
		setPswdError(false);
		setIsOTPSent(false);

	}

	const navigate = useNavigate();

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

	const handleMouseDownPassword1 = (event) => {
		event.preventDefault();
	};

	const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

	const handleMouseDownPassword2 = (event) => {
		event.preventDefault();
	};


	const login = async () => {
		if (!username) {
			toast.error('Please enter your username.', {
				position: "bottom-right",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!password) {
			toast.error('Please enter your password.', {
				position: "bottom-right",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		if (!captcha && shouldShowCaptcha) {
			toast.error('Please verify the ReCAPTCHA to login!', {
				position: "bottom-right",
				autoClose: 2500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				theme: "colored",
			});
			return;
		}
		try {
			const response = await axios.post("/login_user", {
				username: username,
				password: password,
				captcha:  captcha
			}, {
				headers: {
					'Content-Type': 'application/json',
				}
			}
			);
			if (response.status == 200 && response.data !== undefined) {
				setCurrUser(prevState => { return { 'name': response.data.name } })
				setEmail(response.data.email);
				setName(response.data.name);
				setRole(response.data.user_role);
				setCookie('zetta_access_token', response.data.access_token, 1);
				navigate("/home");
			}
			// navigate("/home");
		} catch (err) {
			console.error("Login failed", err);
			if(shouldShowCaptcha){
				window.grecaptcha.reset();
			}
			setCaptcha('');
			if (err.response !== undefined) {
				setMsg(err.response.data.msg)
				setOpen(true)
			}
		}
	};

	// const next = async () => {
	// 	setLoading(true)
	// 	if (step === 1) {
	// 		if (!email2) {
	// 			setEmailError(true);
	// 			setHelperText('Invalid Email ID!')
	// 			setLoading(false)
	// 			return
	// 		}
	// 		else {
	// 			try {
	// 				const response = await axios.post(`/forgot-password/check-email`, { email: email2 }, {
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 					},
	// 				});
	// 				if (response.status == 200 && response.data !== undefined) {
	// 					setLoading(false)
	// 					if(response.data.count){
	// 						setCode(response.data.code)
	// 						setStep(step + 1)
	// 					}else{
	// 						setEmailError(true)
	// 						setHelperText("Email ID not found! Please sign up if you don't have an account.")
	// 					}
	// 				}

	// 			} catch(err) {
	// 				console.error("Error", err);
	// 			}

	// 		}
	// 	}
	// 	else if (step === 2) {
	// 		if (otp === code){
	// 			setLoading(false)
	// 			setStep(step + 1)
	// 		}else{
	// 			setLoading(false)
	// 			setInvalidOTP(true);
	// 			return;
	// 		}
	// 	}
	// 	else {
	// 		if(password1 !== password2){
	// 			setPswdError(true);
	// 			setLoading(false)
	// 			return
	// 		}else{
	// 			try {
	// 				const response = await axios.post(`/forgot-password/reset-password`, { email: email2, password: password1 }, {
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 					},
	// 				});
	// 				if (response.status == 200 && response.data !== undefined) {
	// 					setLoading(false)
	// 					closeModal()
	// 					if(response.data){
	// 						toast.success('Password reset successfully!', {
	// 							position: toast.POSITION.BOTTOM_LEFT,
	// 							autoClose: 2500,
	// 							hideProgressBar: false,
	// 							closeOnClick: true,
	// 							pauseOnHover: false,
	// 							draggable: false,
	// 							theme: "colored",
	// 							type: toast.TYPE.SUCCESS
	// 						});
	// 					}else{
	// 						toast.error('Something went wrong!', {
	// 							position: toast.POSITION.BOTTOM_LEFT,
	// 							autoClose: 2500,
	// 							hideProgressBar: false,
	// 							closeOnClick: true,
	// 							pauseOnHover: false,
	// 							draggable: false,
	// 							theme: "colored",
	// 							type: toast.TYPE.ERROR
	// 						});
	// 					}
	// 				}

	// 			} catch(err) {
	// 				console.error("Error", err);
	// 			}
	// 		}
	// 	}
	// }

	const handleSendOTP = async () => {
		setSendOTPLoading(true);
		const sanitizedEmail = DOMPurify.sanitize(email2);
		if (!sanitizedEmail) {
			setEmailError(true);
			setHelperText('Please enter email ID');
			setSendOTPLoading(false);
			return;
		}
		if (!validateEmail(sanitizedEmail)) {
			setEmailError(true);
			setHelperText('Invalid Email ID');
			setSendOTPLoading(false);
			return;
		}
		setEmailError(false);
		setHelperText('');
		try {
			const response = await axios.post(`/forgot-password/check-email`, { email: sanitizedEmail }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setSendOTPLoading(false);
				if (response.status === 200) {
					setIsOTPSent(true);
				}
				else {
					setEmailError(true);
					setHelperText("Something went wrong");
				}
			}

		} catch (err) {
			setSendOTPLoading(false);
			setEmailError(true);
			if (err.response.status === 422) {
				setHelperText("Unprocessable Entity");
			}
			else if (err.response.status === 403) {
				setHelperText("Account/IP is blocked");
			}
			else if (err.response.status === 500) {
				setHelperText("Internal Server Error");
			}
			else {
				setHelperText("Something went wrong");
			}
		}
	}

	const handleResendOTP = async () => {
		const sanitizedEmail = DOMPurify.sanitize(email2);
		setReSendOTPLoading(true);
		try {
			const response = await axios.post(`/forgot-password/check-email`, { email: sanitizedEmail }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setReSendOTPLoading(false);
				if (response.status === 200) {
					toast.success('OTP sent successfully!', {
						position: "bottom-right",
						autoClose: 2500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						theme: "colored",
					});
				}
			}
		}
		catch (err) {
			setReSendOTPLoading(false);
			setInvalidOTP(true);
			if (err.response.status === 422) {
				setOTPErrorText("Unprocessable Entity");
			}
			else if (err.response.status === 403) {
				setOTPErrorText("Account/IP is blocked");
			}
			else if (err.response.status === 500) {
				setOTPErrorText("Internal Server Error");
			}
			else {
				setOTPErrorText("Something went wrong");
			}
		}
	}

	const handleConfirmOTP = async () => {
		setConfirmOTPLoading(true);
		const sanitizedEmail = DOMPurify.sanitize(email2);
		if (otp.length !== 6) {
			setInvalidOTP(true);
			setOTPErrorText('Enter 6 digit OTP');
			setConfirmOTPLoading(false);
			return;
		}
		try {
			setConfirmOTPLoading(true);
			const response = await axios.post(`/forgot-password/verify-otp`, { email: sanitizedEmail, otp: otp }, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response) {
				setConfirmOTPLoading(false);
				if (response.status === 200) {
					if (atob(response.data) === otp) {
						setStep(step + 1);
					} else {
						setInvalidOTP(true);
						setOTPErrorText("Something went wrong");
					}
				}
				else {
					setInvalidOTP(true);
					setOTPErrorText("Something went wrong");
				}
			}
		}
		catch (err) {
			setConfirmOTPLoading(false);
			setInvalidOTP(true);
			if (err.response.status === 422) {
				setOTPErrorText("Unprocessable Entity");
			}
			else if (err.response.status === 500) {
				setOTPErrorText("Internal Server Error");
			}
			else if (err.response.status === 403) {
				setOTPErrorText("Account/IP is blocked");
			}
			else if (err.response.status === 401) {
				setOTPErrorText("Invalid OTP");
			}
			else {
				setOTPErrorText("Something went wrong");
			}
		}
	}

	const handleResetPassword = async () => {
		setResetPasswordLoading(true);
		if (!password1 || !password2) {
			setPswdValidations(["Please enter password"]);
			setResetPasswordLoading(false);
			return;
		}
		const sanitizedEmail = DOMPurify.sanitize(email2);
		const sanitizedPassword1 = DOMPurify.sanitize(password1);
		const sanitizedPassword2 = DOMPurify.sanitize(password2);
		setPswdValidations([]);
		if (sanitizedPassword1 !== password1 || sanitizedPassword2 !== password2) {
			setPswdValidations(['Character not allowed']);
			setResetPasswordLoading(false);
			return;
		}
		if (password1.length < 8) {
			setPswdValidations(["Password must be atleast 8 characters long"]);
			setResetPasswordLoading(false);
			return;
		}
		if (password1 !== password2) {
			setPswdError(true);
			setPswdValidations([]);
			setResetPasswordLoading(false);
		}
		else {
			setPswdError(false);
			let errors_arr = [];
			!(/[a-z]/.test(password1)) && errors_arr.push("Password must contain atleast one lowercase letter");
			!(/[A-Z]/.test(password1)) && errors_arr.push("Password must contain atleast one uppercase letter");
			!(/[0-9]/.test(password1)) && errors_arr.push("Password must contain atleast one number");
			!(/[!@#$%^&*]/.test(password1)) && errors_arr.push("Password must contain atleast one special character");
			!(password1.length >= 8) && errors_arr.push("Password must be atleast 8 characters long");
			setPswdValidations(errors_arr);

			if (errors_arr.length === 0) {
				try {
					const response = await axios.post(`/forgot-password/reset-password`, { email: sanitizedEmail, password: password1 }, {
						headers: {
							'Content-Type': 'application/json',
						},
					});
					if (response) {
						setResetPasswordLoading(false);
						if (response.status === 200) {
							closeModal();
							toast.success('Password reset successfully!', {
								position: "bottom-right",
								autoClose: 2500,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: false,
								draggable: false,
								theme: "colored",
							});
						}
						else {
							setPswdValidations(["Something went wrong"]);
						}
					}
				}
				catch (err) {
					setResetPasswordLoading(false);
					if (err.response.status === 422) {
						setPswdValidations(["Unprocessable Entity"]);
					}
					else if (err.response.status === 500) {
						setPswdValidations(["Internal Server Error"]);
					}
					else {
						setPswdValidations(["Something went wrong"]);
					}
				}
			}
			else {
				setResetPasswordLoading(false);
			}
		}
	}

	return (
		<>
			{/* <div> */}
			{/*         <h1>Login</h1> */}
			{/*         <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} /> */}
			{/*         <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} /> */}
			{/*         <button onClick={login}>Login</button> */}
			{/*       </div> */}

			<div style={{ backgroundImage: `url(${loginBg})`, width: '100.5vw', height: '100vh' }} className="login-bg row">
				<div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
					{/* <img src={logo} className="login-logo" />

					<p className="mt-4 text-white login-presents"><i>presents</i></p>
					<h2 className="heading-2 text-white mt-4">PROJECT ZETTA</h2> */}
				</div>

				<div className="login-form-section col-sm-12 col-md-6 d-flex justify-content-center align-items-center flex-column">

					<div className='bg-white corner p-5 w-80 d-flex justify-content-center align-items-center flex-column'>
						<h1 className="greeting">WELCOME BACK!</h1>
						<div className="orange-line"></div>

						<div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
							<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
								<TextField id="email" label="Email ID" variant="standard" onChange={(evt) => setUsername(evt.target.value)} />
							</FormControl>
						</div>

						<div class="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
							<FormControl sx={{ m: 1, width: '75%' }} variant="standard">
								<InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
								<Input
									id="standard-adornment-password"
									type={showPassword ? 'text' : 'password'}
									onChange={(evt) => setPassword(evt.target.value)}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
						{shouldShowCaptcha && 
							<div>
								<ReCAPTCHAComponent setCaptcha={setCaptcha} />
							</div>
						}

						<button class="w-70 p-2 btn-orange mt-4" onClick={login}>Login</button>

						<div class="mt-5 login-bottom-section px-3 w-100 row">
							<div class="col-4 d-flex justify-content-center text-center">
								<p class="text-muted"><a href='#' onClick={() => setModalOpen(true)}>Forgot password?</a></p>
							</div>
						</div>
					</div>

				</div>
			</div>


			<Dialog
				open={modalOpen}
				onClose={closeModal}
				maxWidth='lg'
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{
					step === 1 ?
						<>
							<DialogContent>
								<h4 className='bold'>Forgot Password?</h4>
								<div className='mt-3 w-100 row'>
									<div className='col-6 d-flex justify-content-center align-items-center'>
										<img className='padlock' src={padlock} />
									</div>
									<div className='col-6 d-flex flex-column justify-content-center'>
										<div className='border rounded p-3'>
											<p className='text-muted small'>Enter your email address for verification. A 6 digit code will be sent to your email address.</p>
											<div className='d-flex align-items-center gap-3'>
												<TextField error={emailError} helperText={emailError ? helperText : ""} id="email-1" label="Email ID" variant="standard" value={email2} onChange={(evt) => setEmail2(evt.target.value)} disabled={isOTPSent} />
												{
													sendOTPLoading ? <CircularProgress /> : <Button onClick={handleSendOTP} variant='contained' disabled={isOTPSent}>Send OTP</Button>
												}
											</div>
											{
												isOTPSent &&
												<Alert icon={<CheckIcon fontSize="inherit" />} severity="success" className='mt-2 py-0'>
													OTP has been sent
												</Alert>
											}
										</div>

										<div className='otp-input d-flex flex-column justify-content-center align-items-center border rounded p-3 mt-5'>
											<p className='text-muted small m-0'>Enter OTP</p>
											<OtpInput
												disabled={true}
												value={otp}
												onChange={setOtp}
												numInputs={6}
												inputType='number'
												renderInput={(props) => <input {...props}
												/>}
											/>
											{
												invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null
											}
											{
												reSendOTPLoading ? <CircularProgress /> : <Button onClick={handleResendOTP} disabled={!isOTPSent}>Resend OTP</Button>
											}
											{
												confirmOTPLoading ? <CircularProgress /> : <Button variant='contained' disabled={!isOTPSent} onClick={handleConfirmOTP} >Confirm OTP</Button>
											}
										</div>
									</div>
								</div>
							</DialogContent>
							<DialogActions>
								<div className='w-100 p-2 d-flex justify-content-end'>
									<Button onClick={closeModal}>Cancel</Button>
								</div>
							</DialogActions>
						</>
						:
						null
				}
				{/* {
						step === 2 ?
							<>
								<DialogContent>
									<h4 className='bold'>Enter 6 Digit OTP</h4>
									<div className='mt-3 w-100 row'>
										<div className='col-6 d-flex justify-content-center align-items-center'>
											<img className='padlock' src={padlock} />
										</div>
										<div className='otp-input col-6 d-flex flex-column justify-content-center align-items-center'>
											<p className='text-muted small'>Enter your 6 digit OTP recieved on your email address.</p>
											<OtpInput
												value={otp}
												onChange={setOtp}
												numInputs={6}
												renderInput={(props) => <input {...props} />}
											/>
											{
												invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null
											}
											<Button onClick={handleResendOTP}>Resend OTP</Button>
										</div>
									</div>
								</DialogContent>
								<DialogActions>
									<div className='w-100 p-2 d-flex justify-content-end'>
										<Button onClick={closeModal}>Cancel</Button>
										{
											!loading ? <Button onClick={next}>Continue</Button> : <CircularProgress />
										}
									</div>
								</DialogActions>
							</>
							:
							null
					} */}
				{
					step === 2 ?
						<DialogContent>
							<h4 className='bold'>Reset Password</h4>
							<div className='mt-3 w-100 row'>
								<div className='col-5 d-flex justify-content-center align-items-center'>
									<img className='padlock' src={padlock} />
								</div>
								<div className='col-7 d-flex flex-column justify-content-center'>
									<p className='text-muted small'>Set a new password for your account.</p>
									<FormControl sx={{ m: 0.3, width: '100%' }} variant="standard">
										<InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
										<Input
											id="standard-adornment-password"
											type={showPassword1 ? 'text' : 'password'}
											onChange={(evt) => setPassword1(evt.target.value)}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword1}
														onMouseDown={handleMouseDownPassword1}
													>
														{showPassword1 ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
									<FormControl className='mt-2' sx={{ width: '100%' }} variant="standard">
										<InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
										<Input
											id="standard-adornment-password"
											type={showPassword2 ? 'text' : 'password'}
											onChange={(evt) => setPassword2(evt.target.value)}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword2}
														onMouseDown={handleMouseDownPassword2}
													>
														{showPassword2 ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</FormControl>
									{
										pswdError &&
										<p className='text-danger small'>
											Passwords do not match!<br />
										</p>
									}
									{
										pswdValidations.length > 0 &&
										<p className='text-danger small'>
											<ul>
												{
													pswdValidations.map((error, index) => {
														return <li key={index}>{error}</li>
													})
												}
											</ul>
										</p>
									}
									<Button onClick={handleResetPassword} variant='contained' className='mt-4'>Reset Password</Button>
								</div>
							</div>
						</DialogContent>
						:
						null
				}
			</Dialog>

			<Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
					{msg}
				</Alert>
			</Snackbar>

			<ToastContainer />
		</>
	);
}

export default Login;